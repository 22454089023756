import request from '@/utils/request'

/**
 * 员工
 */
export class EmployeeApi {
  // 员工列表
  static index (params) {
    // return request({
    //   url: '/workEmployee/index',
    //   method: 'get',
    //   params
    // })
    return request({
      url: '/crm/employee/getList',
      method: 'get',
      params
    })

  }
}
