import request from "../plugins/axios";


export function topicall(params) {
    return request({
        url: '/crm/topic/list',
        method: 'GET',
        params
    })
}

export function customerList(params) {
    return request({
        url: '/crm/customer/list',
        method: 'GET',
        params
    })
}

export function topicCustomerList(params) {
    return request({
        url: '/crm/topic/customer/list',
        method: 'GET',
        params
    })
}

export function topicSeaCustomerList(params) {
    return request({
        url: '/crm/topic/sea/customer/list',
        method: 'GET',
        params
    })
}

export function topicDetail(params) {
    return request({
        url: '/crm/topic/detail',
        method: 'GET',
        params
    })
}





export function collectFromHighsea(data) {
    return request({
        url: '/crm/customer/collectFromHighsea',
        method: 'put',
        data
    })
}

/**
 * 客户绑定微信联系人
 * @param data
 * @returns {*}
 */
export function bindWorkContact(data) {
    return request({
        url: '/crm/customer/bindWorkContact',
        method: 'put',
        data
    })
}

export function contactIndex(params) {
    return request({
        url: '/workContact/contactIndex',
        method: 'GET',
        params
    })
}
export function batchCreateContactTag(data) {
    return request({
        url: '/workContactTag/batchCreateContactTag',
        method: 'PUT',
        data
    })
}
export function workRoom(params) {
    return request({
        url: '/workRoom/index',
        method: 'GET',
        params
    })
}
