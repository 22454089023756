<template>
  <div>
    <Popup
      v-model:show="transferPop"
      round
      position="bottom"
      :lock-scroll="false"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '490px' }">
      <!--      上一步-->
      <div style="position: relative;height: 100%;" v-show="stepShow==1">
        <div style="font-size: 14px;text-align: center;padding-top: 15px;">
          <div>选择好友</div>
          <div style="font-size: 13px;color: #ACACAC;">选择已有好友,进行绑定</div>
        </div>
        <Divider />
<!--        show_staff-->
        <div class="transfer_page ">
          <div style="display: flex;margin-top: 15px;">
            <div class="row_name">选择好友</div>
          </div>
<!--          <van-search-->
<!--            placeholder="搜索"-->
<!--            @search="searchEmployee"-->
<!--            v-model="employeeName"-->
<!--            @input="clearInput"-->
<!--            :clearable="false"-->
<!--          />-->
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
          >
            <van-radio-group v-model="checkedStaff">
              <van-cell v-for="(item,index) in staffArray" :key="index">
                <div class="transfer_flex" style="cursor: pointer;" @click="selectEmployee(index)">
                  <div style="display: flex;">
                    <img :src="item.avatar" alt="" style="width: 40px;height: 40px;">
                    <span style="margin-left: 10px;line-height: 50px;">{{ item.name }}</span>
                  </div>
                  <van-radio :name="index"></van-radio>
                </div>
              </van-cell>
            </van-radio-group>
          </van-list>
        </div>
        <div class="foot_btn_row">
          <van-button type="info" block @click="confirmTransfer">确定</van-button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { EmployeeApi } from '@/api/employee'
// eslint-disable-next-line no-unused-vars
import { ContactTransferApi } from '@/api/contactTransfer'
import { Divider, Popup, NoticeBar, Toast } from 'vant'
import {bindWorkContact, contactIndex} from "@/api/customer";
export default {
  components: {
    Divider, Popup, NoticeBar, Toast
  },
  data () {
    return {
      transferPop: false,
      finished: true,
      loading: false,
      checkedStaff: -1,
      staffArray: [],
      stepShow: 1,
      tipWord: '',
      modifyTips: '',
      showModify: false,
      replaceName: '',
      employeeName: ''
    }
  },
  methods: {
    selectEmployee (index) {
      this.checkedStaff = index
    },
    // 清空搜索框
    clearInput () {
      if (this.employeeName == '') {
        this.getEmployeeList()
      }
    },
    // 搜索员工
    searchEmployee () {
      this.getEmployeeList()
    },
    show (contactId) {
      this.contactId = contactId
      this.transferPop = true
     this.getEmployeeList()
    },
    getEmployeeList () {
      contactIndex({
        page:1,
        perPage:100
      }).then((res) => {
        this.staffArray = res.data.list
      })
    },
    // 确认转接
    confirmTransfer () {
      // if (this.tipWord == '') {
      //   Toast('客户收到的提示不能为空')
      //   return false
      // }
      bindWorkContact({
        customerId: this.contactId,
        contactId: this.staffArray[this.checkedStaff].id,
        // content: this.tipWord
      }).then((res) => {
        Toast('绑定成功')
        this.transferPop = false
        this.checkedStaff = -1
        this.stepShow = 1
      })
    },
  }
}
</script>
<style scoped lang="less">
.transfer_flex{
  display: flex;
  justify-content:space-between;
}
.transfer_page{
  padding: 0 35px;
  font-size: 28px;
  color: #333;
}
.show_staff{
  height: 720px;
  overflow-y: scroll;
}
.row_name{
  font-size: 14.5px;
  border-left: 7px solid #1890FF;
  height: 17.5px;
  line-height: 20px;
  padding-left: 10px;
}
.foot_btn_row{
  //position: absolute;
  //bottom: 10px;
  //left: 35px;
  margin: 0 auto;
  width: calc(100% - 70px);
}
.show_tip_news{
  padding: 50px 30px 30px;
  background: #F0F0F0;
  margin-top: 15px;
  border-radius: 5px;
}
.tip_content{
  width: 100%;
  background: #fff;
  padding: 10px 20px;
  margin-left: 20px;
  font-size: 10px;
}
.transfer_foot_btn{
  margin-top: 10px;
  margin-bottom: 20px;
  button{
    width: calc(50% - 10px);
    height: 37.5px;
  }
}
.textarea{
  margin-top: 15px;
  width: calc(100% - 60px);
  height: 400px;
  padding: 50px 30px 50px 30px;
  resize:none;
  border: 0;
  background: #F0F0F0;
}
.textarea_btn{
  position: absolute;
  bottom: 30px;
  right: 0;
  span{
    margin-right: 30px;
  }
}
.show-tips-info{
  //min-height: 530px;
}
</style>
