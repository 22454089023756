import request from '@/utils/request'

/**
 * 客户待办事项
 */
export class ContactTodoApi {
  // 添加待办
  // static store (params) {
  //   return request({
  //     url: '/contactTodo/store',
  //     method: 'post',
  //     data: params
  //   })
  // }

  //添加待办-新
  static store (params) {
    return request({
      url: '/crm/customer/createTodo',
      method: 'put',
      data: params
    })
  }
}
