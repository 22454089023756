import request from '@/utils/request'

/**
 * 客户转移
 */
export class ContactTransferApi {
  // 转接客户
  // static transfer (params) {
  //   return request({
  //     url: '/contactTransfer/transfer',
  //     method: 'post',
  //     data: params
  //   })
  // }

  // 转接客户-详情
  static transfer (params) {
    return request({
      url: '/crm/customer/transfer',
      method: 'put',
      data: params
    })
  }
}
